.legal {
    margin-top: 80px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    margin-left: 150px;
    margin-right: 150px;
}

.text-legal {
    margin-top: 0;
    margin-bottom: 20px;
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
    .legal {
        margin-bottom: 70px;
        margin-left: 30px;
        margin-right: 30px;
    }
    }

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
    .legal {
        margin-bottom: 60px;
    }
}