.offer {
  padding: 60px 140px;
  position: relative;
  background: #212534;
  z-index: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: start;
  color: white;
}

.offer-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
}

.offer-content {
  margin-top: 60px;
}

.button-offer {
  background-color: rgb(152, 58, 26);
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  width: 200px;
}

/* START CSS MAGIC CARD*/
@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
}

.card {
  background: #191c29;
  width: 400px;
  height: 100px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 30px;
  color: rgb(88 199 250 / 100%);
}

.card::before {
  content: "";
  width: 410px;
  height: 110px;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  position: absolute;
  z-index: -1;
  top: -5px;
  left: -5px;
  animation: spin 2.5s linear infinite;
}

.card::after {
  position: absolute;
  content: "";
  top: 25px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}

/* END CSS MAGIC CARD*/

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
  .offer {
    padding-left: 30px;
    padding-right: 30px;
  }

  .offer-image {
    width: 45%;
  }

  .offer-content {
    margin-top: 50px;
  }

  .card {
    width: 350px;
    height: 90px;
    font-size: 25px;
  }

  .card::before {
    width: 360px;
    height: 100px;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .offer {
    padding-top: 200px;
  }
  
  .offer-image {
    width: 90%;
  }

  .offer-content {
    margin-top: 25px;
  }

  .card {
    width: 280px;
    height: 70px;
    font-size: 22px;
    margin: auto;
    margin-top: 30px;
  }

  .card::before {
    width: 290px;
    height: 80px;
  }
}
