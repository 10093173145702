.skills {
  background-color: rgb(245, 245, 247);
  padding-bottom: 60px;
  padding-left: 140px;
  padding-right: 140px;
}

.bulles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.bulle {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  transition-property: background-color, opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  opacity: 0;
  transform: translateY(20px);
}

.bulle:hover {
  background-color: rgb(200, 200, 200);
}

.bulle.active {
  transform: scale(1.1);
  background-color: #f7f7f7;
}

.bulle-firstline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.bulle-lastline {
  display: none;
  margin-top: 0px;
  font-size: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.bulle-lastline.active {
  display: block;
}

.bulle.active .bulle-lastline {
  opacity: 1;
}

i {
  font-size: 30px;
}

.skills-text {
  font-size: 20px;
}

.fa-eye {
  color: rgb(200, 158, 81);
}

.fa-circle-check {
  color: rgb(20, 108, 20);
}

.fa-code {
  color: brown;
}

.fa-mobile-screen {
  color: rgb(212, 88, 88);
}

.image-js {
  border-radius: 4px;
}

.image-bulle {
  width: 30px;
}

.quote {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  padding: 2rem 0;
}

.quote-content {
  margin-left: 30px;
  margin-right: 30px;
  display: block;
  font-size: 30px;
  font-family: IBM Plex Sans, sans-serif;
}

.quote-author {
  color: #626262;
  font-size: 16px;
  font-style: italic;
}

.quote-content::before,
.quote-content::after {
  content: '"';
  color: #999;
  position: absolute;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 70px;
}

.quote-content::before {
  left: 0;
  top: 0;
}

.quote-content::after {
  right: 0;
  bottom: -50px;
}

.bulle-softskills {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  transition: opacity 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
}

.bulle-softskills.active {
  opacity: 1;
  transform: translateY(0);
}

.bulle-softskills:hover {
  background-color: rgb(200, 200, 200);
}

.fa-comments {
  color: rgb(58, 116, 124);
}

.fa-fire {
  color: rgb(228, 86, 51);
}

.fa-sliders {
  color:rgb(74, 74, 74);
}

.fa-wand-magic-sparkles {
  color: rgb(171, 0, 108);
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
  .skills {
    padding-left: 30px;
    padding-right: 30px;
  }

  .bulles {
    gap: 20px;
  }

  .bulle {
    padding-left: 20px;
    padding-right: 20px;
  }

  .skills-text {
    font-size: 18px;
  }  

  .bulle-firstline {
    gap: 10px;
  }  

  .bulle-lastline {
    padding-left: 10px;
    padding-right: 10px;
  }

  i {
    font-size: 25px;
  }

  .image-bulle {
    width: 25px;
  }
  
  .quote {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 1.5rem 0;
  }
  
  .quote-content {
    font-size: 23px;
  }
  
  .quote-author {
    font-size: 15px;
  }
  
  .quote-content::before,
  .quote-content::after {
    font-size: 50px;
  }
  
  .bulle-softskills {
    margin-top: 20px;
    gap: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .bulles {
    gap: 15px;
  }

  .bulle {
    padding-left: 15px;
    padding-right: 15px;
  }

  .skills-text {
    font-size: 16px;
  } 
  
  .bulle-firstline {
    gap: 7px;
  }  

  .bulle-lastline {
    padding-left: 15px;
    padding-right: 15px;
  }

  i {
    font-size: 20px;
  }

  .image-bulle {
    width: 20px;
  }

  .quote {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 1rem 0;

  }
  
  .quote-content {
    font-size: 18px;
  }
  
  .quote-author {
    font-size: 12px;
  }
  
  .quote-content::before,
  .quote-content::after {
    font-size: 30px;
  }
    
  .quote-content::after {
    bottom: -10px;
  }

  .bulle-softskills {
    gap: 7px;
    padding-left: 15px;
    padding-right: 15px;
  }
}