.services {
  margin-left: 140px;
  margin-right: 140px;
  padding-bottom: 60px;
}

.title-section {
  font-size: 40px;
  padding-top: 33px;
  margin-top: 0px;
}

h3 {
  color: rgb(152, 58, 26);
}

.title-card-services {
  margin-top: 50px;
  margin-bottom: 30px;
}

.centered-line-service {
  border: none;
  border-top: 1px solid rgb(148, 148, 148);
  width: 80px;
  margin-bottom: 30px;
}

.cards {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.card-services {
  background-color: rgba(245, 245, 247);
  width: 100%;
  height: 400px;
  border-radius: 25px;
  padding: 40px 40px 40px 40px;
  opacity: 0;
  transition: opacity 1s;
}

.icon-card-services {
  width: 100px;
}

.card-services.fade-in {
  opacity: 1;
}

.card-services.fade-out {
  opacity: 0;
}

.green-it {
  color: green;
  text-decoration: none;
}

.green-it:hover {
  text-decoration: underline;
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
  .services {
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .title-section {
    font-size: 35px;
  }

  .card-services {
    width: 30%;
    padding: 20px 20px 20px 20px;
  }

  .icon-card-services {
    width: 80px;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .title-section {
    font-size: 30px;
  }  

  .cards {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .card-services {
    width: 100%;
    height: 200px;
    padding: 10px 10px 10px 10px;
  }
  
  .icon-card-services {
    width: 50px;
  }

  .title-card-services {
    margin-top: 10px;
    font-size: 17px;
    margin-bottom: 20px;
  }  

  .centered-line-service {
    width: 200px;
    margin-bottom: 20px;
  }  

  .text-responsive-design {
    display: none;
  }
}