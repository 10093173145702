.prices {
  margin-left: 140px;
  margin-right: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.container-price {
  width: 100%;
}

.legend-title-price {
  margin-top: 0;
}

.cards-price {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 19px;
  margin-top: 20px;
  width: 100%;
}

.sites-cards {
  display: flex;
  justify-content: space-between;
  gap: 19px;
  width: 67%;
}

.card-price {
  background-color: rgb(245, 245, 247);
  border-radius: 10px 10px 10px 10px;
  width: 100%;
  height: 100%;
}

.title-card-price {
  font-size: 18px;
  margin-top: 0;
  padding: 20px 10px 20px 10px;
  background-color: rgb(152, 58, 26);
  color: white;
  border-radius: 10px 10px 0px 0px;
}

.from-price {
  font-size: 12px;
  color: rgb(63, 63, 63);
}

.price-price {
  font-size: 60px;
  padding-bottom: 15px;
}

.currency-symbol {
  vertical-align: top;
  font-size: 20px;
  font-weight: 300;
}

.centered-line {
  border: none;
  border-top: 5px solid rgb(152, 58, 26);
  width: 50px;
}

.retails {
  color: rgb(66, 66, 66);
  margin-top: 19px;
}

.retail-a {
  height: 50px;
  padding: 6px 20px 6px 20px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.retail-b {
  height: 50px;
  padding: 6px 20px 6px 20px;
  background-color: rgb(230, 230, 230);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-price {
  color: white;
  background-color: rgb(152, 58, 26);
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 7px 10px 7px 10px;
  border-radius: 10px 10px 10px 10px;
  width: 60%;
}

.specific-cards {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.maintenance-price {
  font-size: 16px;
}

.maintenance-symbol {
  vertical-align: top;
  font-size: 13px;
  font-weight: 300;
}

.specific-cards .card-price {
  margin-bottom: 40px;
}

.text-asterix {
  text-align: start;
  font-size: 13px;
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
  .prices {
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .title-card-price {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .cards-price {
    gap: 15px;
    margin-top: 5px;
  }

  .sites-cards {
    gap: 15px;
  }

  .price-price {
    font-size: 50px;
  }

  .currency-symbol {
    font-size: 17px;
  }

  .centered-line {
    border-top: 3px solid rgb(152, 58, 26);
  }

  .retails {
    font-size: 14px;
  }

  .retail-a {
    height: 50px;
    padding: 5px 10px 5px 10px;

  }

  .retail-b {
    height: 50px;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .title-card-price {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
  }

  .cards-price {
    flex-direction: column;
    gap: 20px;
  }

  .sites-cards {
    gap: 10px;
    width: 100%;
  }
  
  .from-price {
    font-size: 9px;
  }

  .price-price {
    font-size: 40px;
  }

  .currency-symbol {
    font-size: 12px;
  }

  .centered-line {
    border-top: 1px solid rgb(152, 58, 26);
  }

  .retails {
    font-size: 12px;
    margin-top: 0;
  }
  
  .retail-a {
    height: 50px;
    padding: 3px 10px 3px 10px;
  }

  .retail-b {
    height: 50px;
    padding: 3px 10px 3px 10px;
  }

  .button-price {
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 5px;
    font-size: 12px;
    width: 75%;
  }

  .specific-cards .card-price {
    margin-bottom: 5px;
  }

  .specific-cards {
    flex-direction: initial;
    gap: 10px;
    width: 100%;
  }
}