.footer {
  background-color: rgb(40, 40, 40);
  color: white;
  font-size: 14px;
  padding: 20px 140px 20px 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  gap: 7px;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.first-link-footer {
  margin-right: 10px;
}

.link-footer {
  margin-left: 10px;
}

.logo-web {
  width: 150px;
}

.social-networks {
  display: flex;
  gap: 20px;
}

.logo-social-networks {
  width: 25px;
  height: 25px;
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
}

.logo-whatsapp:hover {
background-color: rgb(36, 211, 102);
}

.logo-facebook:hover {
  background-color: #1877f2;
}

.logo-instagram:hover {
  background: linear-gradient(49.2deg, #f9ed32 10%, #ee2a7b 55%, #002aff);
}

.logo-linkedin:hover {
  background-color: #4875b4;
}

.rights-reserved {
  display: none;
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
  .footer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column-reverse;
  }
  
  .footer-content {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;

  }

  .links-footer {
    margin-top: 10px;
  }

  .rights-reserved {
    display: block;
  }
}