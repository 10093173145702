.contact {
  background-color: rgb(245, 245, 247);
  padding-bottom: 60px;
  padding-left: 140px;
  padding-right: 140px;
}

.legend-title-contact {
  color: black;
}

.estimate {
  color: rgb(152, 58, 26);
}

.containers-contact {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  gap: 30px;
}

.information {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.retail-information {
  display: flex;
  align-items: start;
  text-align: start;
  gap: 10px;
}

.link-contact {
  text-decoration: none;
  color: rgb(97, 97, 97);
}

.text-information {
  color: rgb(97, 97, 97);
  border-bottom: 1px solid rgb(172, 172, 172);
  margin-top: 0;
  padding-bottom: 25px;
  width: 100%;
}

.text-siren {
  color: rgb(97, 97, 97);
  margin-top: 0;
}

.fa-location-dot,
.fa-phone,
.fa-envelope,
.fa-gear {
  color: rgb(152, 58, 26);
  font-size: 20px;
}

.map-container {
  position: relative;
  width: 100%;
  height: 550px;
}

.map {
  height: 100%;
}

.map-mobile {
  display: none;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-retail {
  display: flex;
  align-items: center;
  height: 40px;
}

.icon-form {
  color: white;
  font-size: 15px;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(182, 182, 182);
  box-sizing: border-box;
}

.icon-width {
  width: 35px;
}

.input-contact {
  height: 100%;
  width: 100%;
  padding: 0 10px;
  border: 1px solid rgb(182, 182, 182);
  box-sizing: border-box;
  color: rgb(50, 50, 50);
}

.input-width {
  width: 100%;

}

.input-contact:focus {
  outline: none;
  border: 1px solid rgb(182, 182, 182);
  box-shadow: none;
}

.form-message {
  display: flex;
  align-items: center;
}

.input-height {
  resize: none;
  height: 150px;
  padding: 10px;
  font-family: BlinkMacSystemFont, sans-serif;
}

.link-accept {
  color: rgb(152, 58, 26);
  text-decoration: none;
}

.link-accept:hover {
  text-decoration: underline;
}

.agree {
  display: flex;
  align-items: start;
  text-align: start;
  gap: 10px;
  margin: auto;
  margin-left: 0;
}

.accept-text {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 0;
}

.button-send {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(152, 58, 26);
  color: white;
  height: 40px;
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
  .contact {
    padding-left: 30px;
    padding-right: 30px;
  }

  .text-information {
    padding-bottom: 25px;
    font-size: 14px;
  }

  .text-siren {
    font-size: 14px;
  }

  .fa-location-dot,
  .fa-phone,
  .fa-envelope,
  .fa-gear {
    font-size: 18px;
  }

  .map-container {
    height: 450px;
  }

  .form {
    gap: 15px;
  }

  .form-retail {
    height: 30px;
  }

  .icon-form {
    font-size: 12px;
    width: 30px;
  }

  .icon-width {
    width: 26px;
  }  

  .agree {
    gap: 7px;
    margin-top: 5px;
    font-size: 12px;
  }

  .button-send {
    height: 30px;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .contact {
    padding: 0;
  }

  .legend-title-contact {
    padding-left: 30px;
    padding-right: 30px;
  }

  .containers-contact {
    flex-direction: column-reverse;
    justify-content: center;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .form {
    gap: 15px;
  }

  .form-retail {
    height: 40px;
  }

  .icon-form {
    font-size: 18px;
    width: 40px;
  }

  .icon-width {
    width: 36px;
  }  

  .input-contact {
    height: 40px;
    border-radius: 0;
  }

  .asterisk-message {
    margin: 0;
    height: 90px;
    width: 37px;
  }

  .input-height {
    height: 90px;
  }

  .agree {
    margin-bottom: 5px;
  }

  .accept-text {
    font-size: 13px;
  }  

  .button-send {
    height: 40px;
    margin-bottom: 30px;
  }

  .information {
    flex-direction: column;
    gap: 20px;
  }

  .text-information {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }

  .text-siren {
    margin-bottom: 30px;
  }

  .map-desktop-tablet {
    display: none;
  }

  .map-mobile {
    display: flex;
    padding: 0;
  }

  .map-container {
    width: 100%;
    height: 200px;
  }
}