header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(
    67,
    67,
    68,
    0.9
  );
  backdrop-filter: blur(
    5px
  );
  -webkit-backdrop-filter: blur(
    5px
  );
}

.logo-header {
  padding-left: 140px;
  width: 30px;
}

.fa-xmark,.fa-bars {
  display: none;
}

.header-link {
  text-decoration: none;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.nav-ico {
  display: flex;
  align-items: center;
}

.nav-buttons {
  display: flex;
  gap: 30px;
  padding-right: 140px;
}

button {
  all: unset;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .logo-header {
    padding-left: 30px;
    width: 30px;
  }  

  .nav-buttons {
    padding-right: 30px;
  }  
}

@media screen and (max-width: 767px) {    
  header {
    background-color: rgba(
      67,
      67,
      68
    );
    height: 23px;
  }
    
  .nav-ico {
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-between;
    width: 100%;
  }

  .logo-header {
    margin-top: 2px;
    padding-left: 0;
  }  

  .header-link {
    font-size: 27px;
    color: rgb(220, 220, 220);
  }  

  .fa-xmark, .fa-bars {
    color: rgb(220, 220, 220);
    font-size: 25px;
    display: inline-block;
    cursor: pointer;
  }
  
  .nav-buttons {
    display: none;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    align-items: start;
    top: 100%;
    width: 100vw;
    height: 100vh;
    padding-top: 0px;
    gap: 20px;
    padding-left: 47px;
    background-color: rgba(
      67,
      67,
      68
    );  
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    animation: slideDown 0.4s forwards;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-3%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .nav-ico.menu-open + .nav-buttons {
    display: flex;
  }
  

  /*Logo Header transition*/
  .nav-ico.menu-open .logo-header {
    opacity: 0;
    transition: 200ms ease-in-out;
  }
  
  .nav-ico.menu-open > .logo-header {
    opacity: 1;
  }
  
  .nav-ico:not(.menu-open) .logo-header {
    opacity: 1;
    transition: 0ms ease-in-out;
  }
  
  .nav-ico:not(.menu-open) > .logo-header {
    opacity: 0;
  }
  /*Logo Header transition */  
}