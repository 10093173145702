.projects {
  margin-bottom: 60px;
  margin-left: 140px;
  margin-right: 140px;
}

.filter-projects {
  margin-top: 50px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  list-style-type: none;
  gap: 15px;
}

.button-filter {
  cursor: pointer;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.button-filter:hover {
  background-color: rgb(152, 58, 26);
  color: white;
}

.button-filter.active {
  background-color: rgb(152, 58, 26);
  color: white;
}

.cards-projects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.link {
  color: black;
  text-decoration: none;
}

.card-project {
  display: flex;
  flex-direction: column;
  background-color: rgb(245, 245, 247);
  width: 31%;
  border-radius: 10px;
  position: relative;
  opacity: 1;
  transition: filter 0.3s ease, box-shadow 0.3s ease;
}

.card-project:hover {
    filter: brightness(1.1);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
}

.image-project {
  border-radius: 10px 10px 0px 0px;
}

.content-project {
  margin: 10px;
  margin-bottom: 50px;
}

.content-bulle {
  border: 1px solid black;
  padding: 5px;
  border-radius: 20px;
  font-size: 12px;
  width: 100px;
  margin: auto;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.card-project:hover .content-bulle {
  background-color: rgb(152, 58, 26);
  color: white;
  border: 1px solid white;
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
  .projects {
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .filter-projects {
    margin-top: 0;
    gap: 10px;
  }
  
  .button-filter {
    padding: 8px 15px;
  }

  .cards-projects {
    gap: 25px;
  }
  
  .card-project {
    width: 30%;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .filter-projects {
    padding-left: 0;
    gap: 7px;
  }

  .button-filter {
    padding: 7px;
  }

  .cards-projects {
    gap: 20px;
  }

  .card-project {
    width: 100%;
  }
}