.ErrorPage {
    margin-bottom: 160px;
    color: rgb(85, 85, 86)
}
.TitleError{
    font-weight: 700;
    font-size: 250px;
    margin-top: 50px;
    margin-bottom: 0px;
    padding: 0;
}

.text-error {
    font-size: 36px;
    margin-top: 30px;
    margin-bottom: 150px;
}

.ComeBack-Home-Error {
    font-size: 18px;
    color: rgb(85, 85, 86);
}

/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {
    .TitleError{
        margin-top: 75px;
        font-size: 150px;
    }
    
    .text-error {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 30px;
        margin-bottom: 100px;
    }  
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
    .TitleError{
        margin-top: 100px;
        font-size: 100px;
    }
    
    .text-error {
        font-size: 20px;
        margin-bottom: 80px;
    }  

    .ComeBack-Home-Error {
        font-size: 14px;
    }    
}