.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%);
}

.banner-content {
  position: absolute;
  top: 35%;
}

.banner-logo {
  width: 300px;
  opacity: 0;
  animation: fadeIn 1s 0.5s forwards;
}

.banner-text {
  color: white;
  font-weight: normal;
  font-size: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0;
  animation: slideUp 1s 1s forwards;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  } 


  /* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .banner-logo {
    width: 200px;
  }

  .banner-text {
    font-size: 16px;
  }
}