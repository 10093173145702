.about {
  height: 470px;
  position: relative;
}

.about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("../../assets/about.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.title-about {
  margin-left: 50%;
  display: flex;
}

.dynamic-title {
  color: rgb(152, 58, 26);
}

.dynamic-word {
  display: inline-block;
}

.content-about {
  margin-left: 50%;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  text-align: start;
}


/* Medium devices (tablets, less/equal than 1024px) */
@media screen and (max-width: 1024px) {  
  .title-about {
    margin-left: 30px;
  }
  
  .about::before {
    filter: blur(200px);
  }

  .content-about {
    margin-left: 30px;
    margin-right: 30px;
  }
}

/* Small devices (phones, less than 768px) */
@media screen and (max-width: 767px) {
  .about {
    margin-bottom: 50px;
  }
  
  .about::before {
    filter: blur(45px);
  }
}